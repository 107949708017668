var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('IndexSlider',{staticClass:"mb-l",attrs:{"slides":_vm.slides,"pending":_vm.bannersPending}}),_c('section',{staticClass:"mb-m mt-m"},[_c('h1',{staticClass:"text-size-h1"},[_vm._v("Привет, "+_vm._s(_vm.user.first_name)+"!")])]),_c('StageStatusPanel',{staticClass:"mb-xl"}),(!_vm.isJunior)?_c('div',{staticClass:"panel radius-xl mb-xl"},[_c('BindYandex')],1):_vm._e(),(_vm.educationBlocks.length)?_c('EducationBlocks',{staticClass:"mb-xl",attrs:{"blocks":_vm.educationBlocks}}):_vm._e(),(_vm.stageActivities.length)?_c('section',{staticClass:"mb-xl"},[_vm._m(0),_c('div',_vm._l((_vm.stageActivities),function(item){return _c('ActivityCard',{key:item.id,staticClass:"mb-xs",attrs:{"activity":item,"score":_vm.activityScores ? _vm.activityScores[item.id] : null}})}),1)]):_vm._e(),_c('section',{staticClass:"mb-xl"},[_c('div',{staticClass:"mb-s"},[_c('h3',{staticClass:"text-size-h3 text-bold"},[_vm._v("Этап 1")]),(_vm.isStudent)?_c('div',[_vm._v("Командный")]):_c('div',[_vm._v("Индивидуальный")])]),(_vm.firstStageProfiles.length)?_c('div',[_c('div',{staticClass:"row cards-list"},_vm._l((_vm.firstStageProfiles),function(profile){return _c('div',{key:profile.id,staticClass:"col-sm-6 col-xs-12 cards-list__item"},[_c('SmallProfileCard',{staticClass:"cards-list__card",attrs:{"title":profile.title,"icon":profile.icon,"profile-type":profile.profileType,"link-to":{
              name: 'user-profile-stage-1',
              params: { profile_id: profile.id },
            }}},[(profile.scores)?_c('div',{staticClass:"prof-scores"},_vm._l((profile.scores),function(item,idx){return _c('ScoreInfo',{key:idx,staticClass:"mb-xs",attrs:{"score":item.score,"track":item.track,"updated":item.updated_at}})}),1):_c('div',{staticClass:"color-meta text-size-s mt-s"},[_vm._v(" Нет данных по набранным баллам ")])])],1)}),0)]):_c('div',[(_vm.isJunior)?_c('p',[_vm._v(" Выбери сферу, в которой планируешь участвовать"),_c('br'),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'user-profiles' }}},[_vm._v("Выбрать сферу")])],1):_c('p',[_vm._v(" Выбери профили и спецпроекты в которых ты будешь участвовать "),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'user-profiles' }}},[_vm._v("Выбрать профили")])],1)])]),(_vm.secondStageProfiles && _vm.secondStageProfiles.length)?_c('section',{staticClass:"mb-xl"},[(_vm.isStudent || _vm.isJunior)?_c('div',{staticClass:"mb-s"},[_c('h3',{staticClass:"text-size-h3 text-bold"},[_vm._v("Финал")])]):_c('div',{staticClass:"mb-s"},[_c('h3',{staticClass:"text-size-h3 text-bold"},[_vm._v("Этап 2")]),_c('div',[_vm._v("Командный")])]),_c('div',{staticClass:"row cards-list"},_vm._l((_vm.secondStageProfiles),function(profile){return _c('div',{key:profile.id,staticClass:"col-sm-6 col-xs-12 cards-list__item"},[_c('SmallProfileCard',{staticClass:"cards-list__card",attrs:{"title":profile.title,"icon":profile.icon,"profile-type":profile.profileType,"link-to":{
            name: 'user-profile-stage-2',
            params: { profile_id: profile.id },
          }}},[(profile.scores)?_c('div',{staticClass:"prof-scores"},_vm._l((profile.scores),function(item,idx){return _c('ScoreInfo',{key:idx,staticClass:"mb-xs",attrs:{"score":item.score,"track":item.track,"updated":item.updated_at}})}),1):_c('div',{staticClass:"color-meta text-size-s mt-s"},[_vm._v(" Нет данных по набранным баллам ")])])],1)}),0)]):_vm._e(),(_vm.thirdStage && _vm.thirdStageProfiles && _vm.thirdStageProfiles.length)?_c('section',{staticClass:"mb-xl"},[_vm._m(1),_c('div',{staticClass:"row cards-list"},_vm._l((_vm.thirdStageProfiles),function(profile){return _c('div',{key:profile.id,staticClass:"col-sm-6 col-xs-12 cards-list__item"},[_c('SmallProfileCard',{staticClass:"cards-list__card",attrs:{"title":profile.title,"icon":profile.icon,"profile-type":profile.profileType,"link-to":{
            name: 'user-profile-stage-3',
            params: { profile_id: profile.id },
          }}})],1)}),0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-s"},[_c('h3',{staticClass:"text-size-h3 text-bold"},[_vm._v("Ближайшее расписание")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-s"},[_c('h3',{staticClass:"text-size-h3 text-bold"},[_vm._v("Финал")])])
}]

export { render, staticRenderFns }